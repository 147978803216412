import filesystemRouting from '@arivaa-react/helpers/filesystem-routing';
import { WithPageData } from '../containers/withPageData';

let obj = {};
export default obj;

/**
 * Import all the routes in pages directory
 */
filesystemRouting(obj, {
  /**
   * Both need  to be passed through directory
   */
  context: require.context('./', true, /\.js$/),
  fileRequire: function (name) {
    return require(`${name}`);
  },
  wrapComponent: WithPageData,
});

// For UI Demos
obj.ui = {
  url: 'ui',
  children: {},
  routeProps: {
    public: true,
    header: false,
    footer: false,
  },
};
