import { BlockEditor } from '../../../containers/blockEditor';
import { DatePicker, Select } from 'antd';
import TagInput from '@arivaa-react/components/tagInput';
import { CrudForm } from '../../../containers/crud/crudForm';
import { useAutoCallApi, useSecureApi } from '../../../../hooks/api';
import { url } from '../../../../constants/api';
import moment from 'moment';

export const PortfolioForm = ({ data, ...props }) => {
  return (
    <CrudForm
      {...props}
      data={
        data
          ? {
              ...data,
              projectDate: moment(data.projectDate),
            }
          : undefined
      }
      elements={[
        {
          type: 'custom',
          name: 'category',
          label: 'Category',
          Component: (props) => {
            const { data } = useAutoCallApi(
              useSecureApi,
              url('portfolio-category'),
              {
                params: {
                  sort: 'createdAt',
                  sortType: 'desc',
                  all: true,
                },
              }
            );
            return (
              <Select className="select" {...props}>
                {(data || []).map(({ key, title }, index) => {
                  return (
                    <Select.Option key={index} value={key}>
                      {title}
                    </Select.Option>
                  );
                })}
              </Select>
            );
          },
          required: true,
        },
        {
          type: 'file',
          name: 'image',
          label: 'Featured Image',
          required: true,
          fileCode: 'mediaFile',
        },
        {
          type: 'file',
          name: 'additionalImages',
          label: 'Additional Images',
          required: false,
          fileCode: 'mediaFile',
          inputProps: {
            multiple: true,
          },
        },
        {
          type: 'text',
          name: 'title',
          label: 'Title',
          required: true,
          inputProps: {
            maxLength: 80,
          },
        },
        {
          type: 'text',
          name: 'shortDescription',
          label: 'Short Description',
          required: true,
          inputProps: {
            maxLength: 240,
          },
        },
        {
          type: 'custom',
          Component: BlockEditor,
          name: 'description',
          label: 'Content',
          required: true,
        },
        {
          type: 'custom',
          Component: TagInput,
          name: 'tags',
          label: 'Tags',
          required: false,
        },
        {
          type: 'text',
          name: 'review',
          label: 'Review',
          required: false,
          inputProps: {
            maxLength: 500,
          },
        },
        {
          type: 'number',
          name: 'rating',
          label: 'Rating',
          required: false,
          inputProps: {
            maxLength: 500,
          },
        },
        {
          type: 'custom',
          name: 'projectDate',
          label: 'Project Date',
          Component: DatePicker,
          required: false,
        },
        {
          type: 'custom',
          name: 'testimonial',
          label: 'Testimonial',
          Component: (props) => {
            const { data } = useAutoCallApi(useSecureApi, url('testimonial'), {
              params: {
                sort: 'createdAt',
                sortType: 'desc',
                all: true,
              },
            });
            return (
              <Select className="select" {...props}>
                {(data || []).map(({ key, title, name }, index) => {
                  return (
                    <Select.Option key={index} value={key}>
                      {`${name} - ${title}`}
                    </Select.Option>
                  );
                })}
              </Select>
            );
          },
          required: false,
        },
        {
          type: 'custom',
          name: 'caseStudy',
          label: 'Case Study',
          Component: (props) => {
            const { data } = useAutoCallApi(useSecureApi, url('case-study'), {
              params: {
                sort: 'createdAt',
                sortType: 'desc',
                all: true,
              },
            });
            return (
              <Select className="select" {...props}>
                {(data || []).map(({ key, title, name }, index) => {
                  return (
                    <Select.Option key={index} value={key}>
                      {`${title}`}
                    </Select.Option>
                  );
                })}
              </Select>
            );
          },
          required: false,
        },
        {
          type: 'text',
          name: 'price',
          label: 'Price',
          required: false,
          inputProps: {
            maxLength: 500,
          },
        },
        {
          type: 'custom',
          name: 'credentials',
          label: 'Credentials',
          Component: TagInput,
          required: false,
        },
        {
          type: 'switch',
          name: 'published',
          label: 'Published',
          required: false,
        },
        {
          type: 'switch',
          name: 'featured',
          label: 'Featured',
          required: false,
        },
      ]}
    />
  );
};
