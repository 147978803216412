import { useCallback, useState } from 'react';
import { message } from 'antd';
import { useSecureApi } from '../../../hooks/api';

import { useCrudData } from '../crud/crudContext';
import { useCrudRecord } from '../crud/recordContext';

/**
 * @description Container to update a entity
 * @type Container
 * @author Inderdeep
 */

export function UpdateEntity(props) {
  const [loading, setLoading] = useState(false);
  const { apiUrl, refresh } = useCrudData();
  const { getChildren } = props;

  const { callApi } = useSecureApi();
  const record = useCrudRecord();

  const updateEntity = useCallback(
    async (data) => {
      const { keyPropName } = props;
      if (typeof data === 'undefined') {
        console.warn('No data defined. Please define data to be updated');
        return;
      }
      setLoading(true);
      try {
        await callApi(`${apiUrl}/${record[keyPropName || 'key']}`, {
          method: 'PATCH',
          body: data,
        });
        refresh();
        message.success('Your changes have been saved successfully');
      } catch (e) {
        console.error('Update Entity', e);
        message.error(
          'There was an error while saving your changes. Please try again.'
        );
      }

      setLoading(false);
    },
    [apiUrl, record, props]
  );

  if (getChildren instanceof Function) {
    return getChildren({
      ...props,
      loading,
      updateEntity,
    });
  }
  return null;
}
