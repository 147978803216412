import SignUpForm from '../../containers/authentication/signUpForm/index';
import { DEFAULT_LOGIN_TYPE } from '../../containers/authentication/defaultLoginLink';
/**
 * @description Sign Up  via phone
 * @type Page
 * @author
 */
const Signup = ({ match }) => {
  return (
    <>
      <SignUpForm type={match?.params?.type || DEFAULT_LOGIN_TYPE} />
    </>
  );
};

Signup.routeProps = {
  header: true,
  public: true,
  guestOnly: true,
};
Signup.url = 'signup/:type?';
Signup.displayName = 'Signup';
export default Signup;
