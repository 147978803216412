import { useCallback } from 'react';
import { url } from '../constants/api';
import { api, secureApi } from './api';
import { useDataState } from './useData';
export function useAuthApi(
  authStorage = { get: () => {}, save: (_) => {}, clear: () => {} }
) {
  const [auth = authStorage?.get(), setAuth] = useDataState('auth');
  const saveAuthData = useCallback(
    (data) => {
      setAuth(data);
      authStorage?.save(data);
    },
    [authStorage]
  );
  const login = useCallback(async (body) => {
    const data = await api(url`authenticate`, {
      body: body,
      method: 'POST',
    });
    saveAuthData(data);
  }, []);
  const logout = useCallback(() => {
    setAuth(undefined);
    authStorage?.clear();
  }, []);
  const refresh = useCallback(async (body) => {
    body = {
      ...body,
      fetchUserInfo: true,
    };
    saveAuthData(
      await secureApi(url`authenticate/refresh`, {
        body: body,
        method: 'POST',
      })
    );
  }, []);
  return {
    auth,
    login,
    logout,
    refresh,
    createLinkWithAuthorizationParam: (url) => {
      const [rest, params] = url.split('?');
      const urlSearchParams = new URLSearchParams(params);
      urlSearchParams.set('authorization', auth?.accessToken);
      return `${rest}?${urlSearchParams.toString()}`;
    },
  };
}
