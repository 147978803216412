import { useEffect, useRef } from 'react';
import { CrudForm } from '../../../containers/crud/crudForm';
import { ALPHANUMERIC_HYPENS } from '@arivaa-react/components/regex';
import useWatchForm from '@arivaa-react/components/form/useWatchForm';
import { Checkbox, Table } from 'antd';
import './styles.scss';
import { useApiWithSideEffects } from '../../../../hooks/api';
import { url } from '../../../../constants/api';
import { translate } from '../../../../localization';
import createSmartInput from '@arivaa-react/components/createSmartInput';

const MultiSelectTable = createSmartInput(
  ({ onChange, data = [], value = [] }) => {
    const dataSource = {};
    data.forEach((e) => {
      const [key, value] = e.key.split('_');
      //for crud permission we store the check for all defined permissions such as READ, CREATE etc
      //which is used to defined table data in the later section
      if (e.type === 'CRUD') {
        dataSource[key] = [...(dataSource[key] || []), value];
      }
      //for custom permission we directly construct the table data
      else {
        dataSource[key] = {
          feature: e.description,
          permissions: [{ label: '', value: e.key }],
        };
      }
    });

    const tableData = Object.keys(dataSource).map((key) => {
      const permissions = dataSource[key];
      return permissions instanceof Array
        ? {
            feature: key,
            permissions: permissions.map((value) => {
              return {
                label: value,
                value: key + '_' + value,
              };
            }),
          }
        : permissions;
    });

    const columns = [
      {
        title: 'Feature',
        dataIndex: 'feature',
        key: 'feature',
        render: (text) => <p>{text}</p>,
      },
      {
        title: 'Permissions',
        dataIndex: 'permissions',
        key: 'permissions',
        render: (permissions = []) => {
          return (
            <>
              {permissions.map((permission, index) => {
                return (
                  <Checkbox
                    key={index}
                    checked={value.includes(permission.value)}
                    onChange={(e) => {
                      const { checked } = e.target;
                      onChange(
                        checked
                          ? [...value, permission.value]
                          : value.filter((p) => p !== permission.value)
                      );
                    }}
                  >
                    {permission.label}
                  </Checkbox>
                );
              })}
            </>
          );
        },
      },
    ];
    return (
      <Table dataSource={tableData} columns={columns} pagination={false} />
    );
  }
);
/**
 * @description Role Form
 * @type Container
 * @author Inderdeep
 */
const Main = (props) => {
  const { data } = props;
  const form = useRef(null);
  const requiredPermissions = useWatchForm(form, 'permissions');
  const spinnerSelector = '.ant-form';
  const { data: permissions, callApi: getPermissions } = useApiWithSideEffects({
    spinnerSelector,
  });

  useEffect(() => {
    getPermissions(url`permission`, {
      params: {
        all: true,
      },
    });
  }, []);

  const tableData = [
    {
      key: '1',
      role: 'Role Name 1',
      permissions: [
        { label: 'Apple', value: 'Apple' },
        { label: 'Pear', value: 'Pear' },
        { label: 'Orange', value: 'Orange' },
      ],
    },
    {
      key: '2',
      role: 'Role Name 2',
      permissions: [
        { label: 'Apple', value: 'Apple' },
        { label: 'Pear', value: 'Pear' },
        { label: 'Orange', value: 'Orange' },
      ],
    },
    {
      key: '3',
      role: 'Role Name 3',
      permissions: [
        { label: 'Apple', value: 'Apple' },
        { label: 'Pear', value: 'Pear' },
        { label: 'Orange', value: 'Orange' },
      ],
    },
  ];

  return (
    <CrudForm
      {...props}
      skipTranslate={true}
      ref={form}
      renderForm={({ renderElement }) => {
        return (
          <div className="form">
            <div className="inline">
              {renderElement({
                type: 'text',
                name: 'code',
                label: translate('role.code.title'),
                required: true,
                options: {
                  rules: [
                    {
                      pattern: ALPHANUMERIC_HYPENS,
                      message:
                        'Code can only be alphanumeric and can contain only hyphens (-)',
                    },
                  ],
                },
                inputProps: {
                  disabled: !!data,
                  className: 'uppercase',
                },
              })}
              {renderElement({
                type: 'text',
                name: 'description',
                label: translate('role.description.title'),
                required: true,
              })}
              {/* {renderElement({
                type: 'select',
                name: 'permissions',
                label: translate('role.permission.placeholder'),
                required: false,
                inputProps: {
                  mode: 'multiple',
                },
                data: (permissions || []).map((item, index) => {
                  const { key, description, requiredPermissions } = item;
                  return {
                    label: `${description} (${
                      (requiredPermissions || []).length
                    } Required Permissions)`,
                    value: key,
                  };
                }),
                after: requiredPermissions?.length > 0 && (
                  <div className="final-permissions">
                    <p className="label">Final Permissions:</p>
                    <ul>
                      {requiredPermissions.map((permission, index) => {
                        return (
                          <li key={index} className="permission">
                            {permission}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ),
              })} */}
            </div>
            {/* {renderElement({
              type: 'text',
              name: 'description',
              label: translate('role.description.title'),
              required: true,
            })} */}
            {renderElement({
              type: 'custom',
              name: 'permissions',
              label: translate('role.permission.placeholder'),
              required: false,
              inputProps: {
                mode: 'multiple',
                data: permissions,
              },
              Component: MultiSelectTable,
            })}
          </div>
        );
      }}
      preSubmit={(values) => {
        if (!data) {
          values.key = values?.key?.toUpperCase();
        }
        return values;
      }}
    />
  );
};

Main.displayName = 'Role-Form';

export default Main;
