import { ModalTrigger } from '@arivaa-react/components/modal';
import { Button, Tooltip } from 'antd';
import { SeoForm } from '../../containers/seoForm';
import { TagsOutlined, RadarChartOutlined } from '@ant-design/icons';
import { WebCrud } from '../../containers/crud/webCrud';
import { Crud } from '../../containers/crud/crud';
import ConfigurationForm from './form';
import { Table } from '../../containers/crud/table';
import { translate } from '../../../localization';
import { useCrudRecord } from '../../containers/crud/recordContext';
import { useCrudData } from '../../containers/crud/crudContext';
import { getRouteUrls } from '@arivaa-react/react-router5';
export default function PageConfiguration() {
  return (
    <WebCrud api="page-configuration" FormComponent={ConfigurationForm}>
      <Crud.Header title="System Pages">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'select',
                  name: 'routeUrl',
                  label: translate('systemPage.routeUrl.label'),
                  required: true,
                  data: getRouteUrls(),
                },
              ]}
            />
          ),
          // () => {
          //   const record = useCrudRecord();
          //   const { key } = record;
          //   return (
          //     <DuplicateForm
          //       entityName="page-configuration"
          //       entityId={key}
          //       getData={refresh}
          //       auto={true}
          //     />
          //   );
          // },
          () => {
            const record = useCrudRecord();
            const { refresh } = useCrudData();
            const { key, metaTags } = record || {};
            return (
              <ModalTrigger
                modalProps={{
                  title: 'Meta Tags',
                  footer: null,
                  className: 'have-form-sections',
                }}
                content={
                  <SeoForm
                    getTableData={refresh}
                    entityId={key}
                    data={metaTags}
                    fieldName="metaTags"
                  />
                }
              >
                <Tooltip title="Manage Meta Tags">
                  <Button className="btn yellow-btn-text">
                    <TagsOutlined />
                  </Button>
                </Tooltip>
              </ModalTrigger>
            );
          },
          () => {
            const record = useCrudRecord();
            const { refresh } = useCrudData();
            const { key, properties } = record || {};
            return (
              <ModalTrigger
                modalProps={{
                  title: 'Properties',
                  footer: null,
                  className: 'have-form-sections',
                }}
                content={
                  <SeoForm
                    getTableData={refresh}
                    entityId={key}
                    data={properties}
                    fieldName="properties"
                  />
                }
              >
                <Tooltip title="Properties">
                  <Button className="btn yellow-btn-text">
                    <RadarChartOutlined />
                  </Button>
                </Tooltip>
              </ModalTrigger>
            );
          },
        ]}
        columns={[
          {
            title: translate('configuration.url'),
            dataIndex: 'routeUrl',
          },
          {
            title: translate('configuration.pageTitle'),
            dataIndex: 'pageTitle',
          },
        ]}
      ></Crud.Table>
      <Crud.Table.TableActions.Common />
      <Crud.Table.Header>
        <Crud.Table.BulkRowActions.Common />
      </Crud.Table.Header>
    </WebCrud>
  );
}
