import { useCallback, useEffect } from 'react';
import './styles.scss';
import Form from '@arivaa-react/components/form';
import { message, Row, Col } from 'antd';
import { BlockEditor } from '../../crud-module/containers/blockEditor';
import { getObjectsDiffKeys } from '@arivaa-react/helpers/common';
import { useSpinner } from '../../hooks/useSpinner';
import { useApiWithSideEffects, useSecureApi } from '../../hooks/api';
import { url } from '../../constants/api';
import { translate } from '../../localization';

const inputType = (type) => {
  switch (type) {
    case 'text':
      return { type };
    case 'boolean':
      return { type: 'switch' };
    case 'file':
      return {
        type,
        inputProps: {
          uploadProps: {
            accept: '*/*',
            multiple: false,
          },
        },
      };
    case 'html':
      return {
        type: 'custom',
        Component: BlockEditor,
      };
    default:
      return { type };
  }
};

export const FormConfiguration = (props) => {
  const { configStartPrefix, pageTitle } = props;
  const className = 'configuration-form ' + configStartPrefix;
  const spinnerSelector = '.' + className;
  const spinner = useSpinner(spinnerSelector);

  const { data: mailConfig, callApi: getMailConfig } = useApiWithSideEffects({
    spinnerSelector,
  });

  const { callApi } = useSecureApi();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        if (mailConfig instanceof Array && mailConfig.length > 0) {
          spinner.start();
          const newObj = mailConfig.map((el) => {
            const tempObj = {};
            tempObj[el.key] = el.value;
            return tempObj;
          });

          const oldObj = {};
          newObj.forEach((item) => {
            Object.assign(oldObj, item);
          });

          const updatedKeys = getObjectsDiffKeys(oldObj, values);
          if (!updatedKeys.length) {
            spinner.stop();
            return message.warning('Cannot find any changes, please try again');
          }

          const responses = updatedKeys.map(async (key) => {
            try {
              Promise.resolve(
                await callApi(url(`configuration/${key}`), {
                  method: 'PATCH',
                  body: { value: values[key] },
                })
              );
            } catch (e) {
              return Promise.reject(e);
            }
          });
          try {
            await Promise.all(responses);
            message.success(translate('common.changes.save.success'));
          } catch (err) {
            message.error(translate('common.changes.save.error'));
            console.error(err);
          } finally {
            spinner.stop();
          }
        }
      } catch (e) {
        console.error(e);
        message.error(translate('common.changes.save.error'));
      }
      spinner.stop();
    },
    [mailConfig]
  );
  useEffect(() => {
    getMailConfig(url`configuration`, {
      params: {
        search: `key;${configStartPrefix};like`,
        all: true,
      },
    });
  }, []);

  return (
    <div className={className}>
      <Form
        onSubmit={handleSubmit}
        renderForm={({ renderElement }) => {
          return (
            <div className="form-configuration">
              <p className="page-title">{pageTitle}</p>
              <div className="fields">
                <Row gutter={24}>
                  {(mailConfig || []).map(
                    ({ description, key, type, value }, index) => (
                      <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
                        {renderElement({
                          key,
                          ...inputType(type),
                          label: description,
                          name: key,
                          options: {
                            initialValue: value,
                          },
                        })}
                      </Col>
                    )
                  )}
                </Row>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
