import { CrudForm } from '../../../containers/crud/crudForm';
import { getRouteUrls } from '@arivaa-react/react-router5';
import { api } from '../../../../hooks/api';
import { translate } from '../../../../localization';

/**
 * @description Page Configuration Form
 * @type Container
 * @author Inderdeep
 */
const Main = (props) => {
  return (
    <CrudForm
      {...props}
      skipTranslate={true}
      elements={[
        {
          type: 'select',
          name: 'routeUrl',
          label: translate('systemPage.routeUrl.label'),
          required: true,
          data: getRouteUrls(),
        },
        {
          type: 'text',
          name: 'pageTitle',
          label: translate('systemPage.pageTitle.label'),
          required: true,
        },
      ]}
      postSubmit={() => {
        api('/refresh-config');
      }}
      entity="page-configuration"
    />
  );
};

Main.displayName = 'Configuration-Form';

export default Main;
