import { Switch } from 'antd';
import { Crud } from '../../containers/crud/crud';
import { WebCrud } from '../../containers/crud/webCrud';
import { translate } from '../../../localization';
import { UpdateEntity } from '../../containers/updateEntity';
import { useCrudData } from '../../containers/crud/crudContext';
import { formatDate } from '@arivaa-react/helpers/date-util';
import { BlogPostForm } from './form';
import { ViewPostRowAction } from '../../containers/viewPostRowAction';

export default function BlogPost() {
  const { refresh } = useCrudData();
  return (
    <WebCrud api="blog-post" FormComponent={BlogPostForm}>
      <Crud.Header title="Blog Post">
        <Crud.Header.Actions.Common>
          <Crud.Header.Actions.Add
            modalProps={{
              className: 'large',
            }}
          />
        </Crud.Header.Actions.Common>
      </Crud.Header>
      <Crud.Table
        actions={[
          (props) => (
            <Crud.Table.RowActions.Edit
              modalProps={{
                className: 'large',
              }}
              {...props}
            />
          ),
          Crud.Table.RowActions.Delete,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new title',
                  name: 'title',
                  inputProps: {
                    placeholder: 'title',
                  },
                },
              ]}
            />
          ),
          () => <ViewPostRowAction postType={'blog'} />,
        ]}
        columns={[
          {
            title: translate('common.title'),
            dataIndex: 'title',
          },
          {
            title: 'Featured',
            dataIndex: 'featured',
            render: (value, record) => {
              return (
                <UpdateEntity
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.featured}
                        onChange={async (checked) => {
                          await updateEntity({ featured: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: 'Published',
            dataIndex: 'published',
            render: (value, record) => {
              return (
                <UpdateEntity
                  record={record}
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.published}
                        onChange={async (checked) => {
                          await updateEntity({ published: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: 'Published At',
            dataIndex: 'createdAt',
            render: (text) => {
              return formatDate(text);
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                name: 'title',
                type: 'text',
                inputProps: {
                  placeholder: translate('common.title'),
                },
              },
              {
                name: 'shortDescription',
                type: 'text',
                inputProps: {
                  placeholder: translate('blogPost.shortDescription'),
                },
              },
              {
                label: translate('blogPost.published'),
                name: 'published',
                type: 'custom',
                Component: Switch,
              },
              {
                label: translate('blogPost.featured'),
                name: 'featured',
                type: 'custom',
                Component: Switch,
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
