import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { ApiForm } from '../../../containers/apiForm';
import { url } from '../../../constants/api';
/**
 * Profile Information Form
 *
 */
const ProfileInformation = () => {
  const { user } = useCurrentUser();

  return (
    <div className="setting user-information">
      <ApiForm
        submitButtonProps={{
          children: 'Save',
        }}
        apiUrl={url(`users/current/update`)}
        apiConfig={{
          method: 'PATCH',
        }}
        elements={[
          {
            type: 'file',
            name: 'photoURL',
            label: 'Profile Image',
            required: false,
            options: {
              initialValue: { url: user.photoURL, type: 'image/png' },
            },
            fileCode: 'profileImage',
          },
          {
            type: 'text',
            name: 'name',
            label: 'Name',
            required: true,
            options: {
              initialValue: user.name,
            },
          },
        ]}
      />
    </div>
  );
};

export default ProfileInformation;
