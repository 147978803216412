import { BlockEditor } from '../../containers/blockEditor';
import { Switch } from 'antd';
import { UpdateEntity } from '../../containers/updateEntity';
import TagInput from '@arivaa-react/components/tagInput';
import { formatDate } from '@arivaa-react/helpers/date-util';
import { Crud } from '../../containers/crud/crud';
import { CrudForm } from '../../containers/crud/crudForm';
import { Table } from '../../containers/crud/table';
import { translate } from '../../../localization';
import { useCrudData } from '../../containers/crud/crudContext';
import { WebCrud } from '../../containers/crud/webCrud';
import { ViewPostRowAction } from '../../containers/viewPostRowAction';

export default function CaseStudy() {
  const { refresh } = useCrudData();
  return (
    <WebCrud
      api="case-study"
      FormComponent={(props) => (
        <CrudForm
          {...props}
          elements={[
            {
              type: 'file',
              name: 'image',
              label: translate('common.image'),
              required: true,
              fileCode: 'mediaFile',
            },
            {
              type: 'text',
              name: 'title',
              label: translate('common.title'),
              required: true,
              inputProps: {
                maxLength: 200,
              },
            },
            {
              type: 'text',
              name: 'shortDescription',
              label: translate('caseStudy.shortDescription'),
              required: true,
              inputProps: {
                maxLength: 500,
              },
            },
            {
              type: 'custom',
              Component: BlockEditor,
              name: 'description',
              label: translate('caseStudy.description'),
              required: true,
            },
            {
              type: 'custom',
              Component: TagInput,
              name: 'tags',
              label: translate('common.tags'),
              required: false,
            },
            {
              type: 'switch',
              name: 'published',
              label: translate('caseStudy.published'),
              required: false,
            },
            {
              type: 'switch',
              name: 'featured',
              label: translate('caseStudy.featured'),
              required: false,
            },
          ]}
        ></CrudForm>
      )}
    >
      <Crud.Header title="Case Study">
        <Crud.Header.Actions.Common>
          <Crud.Header.Actions.Add
            modalProps={{
              className: 'large',
            }}
          />
        </Crud.Header.Actions.Common>
      </Crud.Header>
      <Crud.Search
        fields={[
          {
            name: 'title',
            type: 'text',
            inputProps: {
              placeholder: translate('common.title'),
            },
          },
          {
            name: 'shortDescription',
            type: 'text',
            inputProps: {
              placeholder: translate('caseStudy.shortDescription'),
            },
          },
          {
            inputProps: {
              placeholder: translate('caseStudy.published'),
            },
            name: 'published',
            type: 'custom',
            Component: Switch,
          },
          {
            inputProps: {
              placeholder: translate('caseStudy.featured'),
            },
            name: 'featured',
            type: 'custom',
            Component: Switch,
          },
        ]}
      ></Crud.Search>
      <Crud.Table
        actions={[
          ...Table.RowActions.Common,
          Crud.Table.RowActions.DuplicateRecordWithForm,
          () => <ViewPostRowAction postType={'case-study'} />,
        ]}
        columns={[
          {
            title: translate('common.title'),
            dataIndex: 'title',
          },
          {
            title: translate('caseStudy.featured'),
            dataIndex: 'featured',
            render: (_, record) => {
              return (
                <UpdateEntity
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.featured}
                        onChange={async (checked) => {
                          await updateEntity({ featured: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: translate('caseStudy.published'),
            dataIndex: 'published',
            render: (_, record) => {
              return (
                <UpdateEntity
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.published}
                        onChange={async (checked) => {
                          await updateEntity({ published: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: translate('caseStudy.createdAt'),
            dataIndex: 'createdAt',
            render: (text) => {
              return formatDate(text);
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
