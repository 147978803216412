import { Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useCrudRecord } from './crud/recordContext';

export function ViewPostRowAction({ postType }) {
  const { key } = useCrudRecord();
  return (
    <Tooltip title="View">
      <a href={`https://laxaar.com/${postType}/${key}`} target="_blank" rel="noreferrer">
        <Button className="btn yellow-btn-text">
          <EyeOutlined />
        </Button>
      </a>
    </Tooltip>
  );
}
