import { CrudForm } from '../../containers/crud/crudForm';
import { translate } from '../../../localization';
import { Crud } from '../../containers/crud/crud';
import { formatDate } from '@arivaa-react/helpers/date-util';
import { Switch } from 'antd';
import { useCrudData } from '../../containers/crud/crudContext';
import { UpdateEntity } from '../../containers/updateEntity';
import { WebCrud } from '../../containers/crud/webCrud';
export default function Testimonials() {
  const { refresh } = useCrudData();
  return (
    <WebCrud
      api="testimonial"
      FormComponent={(props) => (
        <CrudForm
          {...props}
          elements={[
            {
              type: 'text',
              name: 'title',
              label: translate('testimonial.title'),
              required: true,
              inputProps: {
                maxLength: 5000,
              },
            },
            {
              type: 'file',
              name: 'image',
              label: translate('testimonial.image'),
              required: false,
              fileCode: 'mediaFile',
            },
            {
              type: 'text',
              name: 'name',
              label: translate('testimonial.name'),
              required: true,
              inputProps: {
                maxLength: 200,
              },
            },
            {
              type: 'text',
              name: 'company',
              label: translate('testimonial.company'),
              required: true,
              inputProps: {
                maxLength: 200,
              },
            },
            {
              type: 'text',
              name: 'designation',
              label: translate('testimonial.designation'),
              required: true,
              inputProps: {
                maxLength: 200,
              },
            },
            {
              type: 'number',
              name: 'rating',
              label: translate('testimonial.rating'),
              required: false,
              inputProps: {
                maxLength: 500,
              },
            },
            {
              type: 'switch',
              name: 'published',
              label: translate('testimonial.published'),
              required: false,
            },
            {
              type: 'switch',
              name: 'featured',
              label: translate('testimonial.featured'),
              required: false,
            },
          ]}
        />
      )}
    >
      <Crud.Header title="Testimonials">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Crud.Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new title',
                  name: 'title',
                  inputProps: {
                    placeholder: 'title',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            title: translate('testimonial.name'),
            dataIndex: 'name',
          },
          {
            title: translate('testimonial.title'),
            dataIndex: 'title',
          },
          {
            title: translate('testimonial.rating'),
            dataIndex: 'rating',
          },
          {
            title: translate('testimonial.featured'),
            dataIndex: 'featured',
            render: (value, record) => {
              return (
                <UpdateEntity
                  entityName="testimonial"
                  record={record}
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.featured}
                        onChange={async (checked) => {
                          await updateEntity({ featured: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: translate('testimonial.published'),
            dataIndex: 'published',
            render: (value, record) => {
              return (
                <UpdateEntity
                  entityName="testimonial"
                  record={record}
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.published}
                        onChange={async (checked) => {
                          await updateEntity({ published: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: translate('testimonial.publishedAt'),
            dataIndex: 'createdAt',
            render: (text) => {
              return formatDate(text);
            },
          },
        ]}
      >
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                name: 'title',
                type: 'text',
                inputProps: {
                  placeholder: translate('common.title'),
                },
              },
              {
                label: translate('testimonial.published'),
                name: 'published',
                type: 'custom',
                Component: Switch,
              },
              {
                label: translate('testimonial.featured'),
                name: 'featured',
                type: 'custom',
                Component: Switch,
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
        <Crud.Table.TableActions.Common />
      </Crud.Table>
    </WebCrud>
  );
}
