import { CrudForm } from '../../containers/crud/crudForm';
import { translate } from '../../../localization';
import { Crud } from '../../containers/crud/crud';
import { WebCrud } from '../../containers/crud/webCrud';
export default function BlogCategory() {
  return (
    <WebCrud
      api="blog-category"
      FormComponent={(props) => (
        <CrudForm
          {...props}
          elements={[
            {
              type: 'text',
              name: 'title',
              label: translate('common.title'),
              required: true,
              maxLength: 200,
            },
            {
              type: 'textarea',
              name: 'description',
              label: translate('portfolioCategory.description'),
              required: false,
            },
          ]}
        />
      )}
    >
      <Crud.Header title="Blog Categories">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Crud.Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new title',
                  name: 'title',
                  inputProps: {
                    placeholder: 'title',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            title: translate('common.title'),
            dataIndex: 'title',
          },
          {
            title: translate('portfolioCategory.description'),
            dataIndex: 'description',
          },
        ]}
      >
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                name: 'title',
                type: 'text',
                inputProps: {
                  placeholder: translate('common.title'),
                },
              },
              {
                name: 'description',
                type: 'text',
                inputProps: {
                  placeholder: translate('portfolioCategory.description'),
                },
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
        <Crud.Table.TableActions.Common />
      </Crud.Table>
    </WebCrud>
  );
}
