import { useRef, useCallback, useEffect } from 'react';
import './styles.scss';
import { CrudForm } from '../../../containers/crud/crudForm';
import { BlockEditor } from '../../../containers/blockEditor';
import { ALPHANUMERIC_HYPENS } from '@arivaa-react/components/regex';
import Tags from '@arivaa-react/components/tags';
import { HtmlPreview } from '../../../containers/htmlPreview';
import { Row, Col } from 'antd';
import { useApiWithSideEffects } from '../../../../hooks/api';
import { url } from '../../../../constants/api';
import { useCrudRecord } from '../../../containers/crud/recordContext';
import { translate } from '../../../../localization';

/**
 * @description Email Template Form
 * @type Container
 * @author Inderdeep
 */

const Main = (props) => {
  let data = useCrudRecord();
  const form = useRef(null);
  const editorRef = useRef();

  if (data) {
    data = { ...data, ...data.metadata };
  }
  const spinnerSelector = '.ant-form';

  const { data: pdfTemplate, callApi: getPdfTemplates } = useApiWithSideEffects(
    {
      spinnerSelector,
    }
  );
  const {
    data: preDefinedTemplates,
    callApi: getPreDefinedTemplates,
  } = useApiWithSideEffects({
    spinnerSelector,
  });

  //replaceAlltext==true then replace whole editor value with new value
  const onClickHandler = useCallback((variable, replaceAllText) => {
    if (replaceAllText === true) {
      editorRef.current.setContent('');
      editorRef.current.execCommand('mceInsertContent', false, variable + ' ');
      return;
    }
    editorRef.current.execCommand('mceInsertContent', false, variable + ' ');
  }, []);

  const onTagsClick = useCallback((value) => {
    onClickHandler(`{{${value}}}`, false);
  }, []);
  const onTemplateClick = useCallback((value) => {
    onClickHandler(value, true);
  }, []);

  useEffect(() => {
    getPdfTemplates(url('html-template'), {
      params: { search: 'type;pdf', all: true },
    });

    getPreDefinedTemplates(url('html-template'), {
      params: { search: 'type;email', all: true },
    });
  }, []);
  return (
    <CrudForm
      {...props}
      skipTranslate={true}
      ref={form}
      renderForm={({ renderElement }) => {
        return (
          <div className="template-builder">
            <Row>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="form">
                  <div className="inline">
                    {renderElement({
                      type: 'text',
                      name: 'code',
                      label: translate('template.key.label'),
                      required: true,
                      options: {
                        rules: [
                          {
                            pattern: ALPHANUMERIC_HYPENS,
                            message:
                              'Key can only be alphanumeric and can contain only hyphens (-)',
                          },
                        ],
                      },
                      inputProps: {
                        disabled: !!data,
                        className: 'uppercase',
                      },
                    })}
                    {renderElement({
                      type: 'email',
                      name: 'from',
                      label: translate('template.from.placeholder'),
                      required: false,
                    })}
                  </div>
                  <div className="inline">
                    {renderElement({
                      type: 'text',
                      name: 'subject',
                      label: translate('template.subject.label'),
                      required: true,
                    })}
                    {renderElement({
                      type: 'select',
                      name: 'relatedTemplateId',
                      label: translate('template.pdfTemplate.label'),
                      required: false,
                      data: (pdfTemplate || []).map(({ key, code }) => {
                        return {
                          label: code,
                          value: key,
                        };
                      }),
                    })}
                  </div>
                  {renderElement({
                    type: 'custom',
                    name: 'templateVariables',
                    label: 'Template Variables',
                    Component: Tags,
                    required: false,
                    inputProps: {
                      tagProps: {
                        onClick: (evt) => onTagsClick(evt.target.innerText),
                      },
                      allowNewTag: true,
                      editable: true,
                    },
                    after: (
                      <div className="templates-container">
                        <p className="label">Choose Template:</p>
                        <div className="templates">
                          {(preDefinedTemplates || []).map((item, i) => {
                            if (item.isSystem) {
                              return (
                                <HtmlPreview
                                  key={i}
                                  className={'template'}
                                  onClick={onTemplateClick}
                                  value={item}
                                />
                              );
                            }
                          })}
                        </div>
                      </div>
                    ),
                  })}
                  {renderElement({
                    type: 'switch',
                    name: 'isSystem',
                    label: 'System Template',
                    required: false,
                  })}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="preview">
                  {renderElement({
                    type: 'custom',
                    name: 'template',
                    label: translate('template.template.label'),
                    required: true,
                    Component: BlockEditor,
                    inputProps: {
                      editorProps: {
                        onInit: (evnt, editor) => (editorRef.current = editor),
                      },
                    },
                  })}
                </div>
              </Col>
            </Row>
          </div>
        );
      }}
      data={data}
      preSubmit={(values) => {
        values.type = 'email';
        const metadata = ['subject', 'from', 'relatedTemplateId'];
        values.metadata = {};
        metadata.forEach((ele) => {
          //pick the values from data in case of update since they are undefined if no changes were made
          values.metadata[ele] = values[ele] || data?.[ele];
          delete values[ele];
        });
        if (!data) {
          values.code = values?.code?.toUpperCase();
        }
        return values;
      }}
    />
  );
};

Main.displayName = 'Email-Template-Form';

export default Main;
