import { TestForm } from '../../containers/testForm';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { Button, Tooltip } from 'antd';
import { FileDoneOutlined } from '@ant-design/icons';
import { WebCrud } from '../../containers/crud/webCrud';
import { Crud } from '../../containers/crud/crud';
import EmailTemplateForm from './form';

import { translate } from '../../../localization';
import { url } from '../../../constants/api';
import { useCrudRecord } from '../../containers/crud/recordContext';
export default function HtmlTemplate() {
  return (
    <WebCrud
      api="html-template"
      query={{ search: 'type;email' }}
      FormComponent={EmailTemplateForm}
    >
      <Crud.Header title="Email Template">
        <Crud.Header.Actions.Common>
          <Crud.Header.Actions.Add
            modalProps={{
              className: 'large',
            }}
          />
        </Crud.Header.Actions.Common>
      </Crud.Header>
      <Crud.Table
        actions={[
          () => (
            <Crud.Table.RowActions.Edit
              modalProps={{
                className: 'large',
              }}
            />
          ),
          Crud.Table.RowActions.Delete,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new Code',
                  name: 'code',
                  inputProps: {
                    placeholder: 'Code',
                  },
                },
              ]}
            />
          ),
          () => {
            const record = useCrudRecord();
            const { code } = record;

            return (
              <ModalTrigger
                modalProps={{
                  title: 'Test Email Template',
                  footer: null,
                  className: 'test-email-template-modal small-width',
                }}
                content={
                  <TestForm
                    apiUrl={url`emails/test`}
                    data={{
                      template: code,
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Email is Required',
                      },
                      {
                        type: 'email',
                        message: 'Valid Email is Required',
                      },
                    ]}
                    placeholder="Enter Email"
                    label="Email"
                  />
                }
              >
                <Tooltip title="Test">
                  <Button className="btn blue-btn-text">
                    <FileDoneOutlined />
                  </Button>
                </Tooltip>
              </ModalTrigger>
            );
          },
        ]}
        columns={[
          {
            title: translate('template.key.label'),
            dataIndex: 'code',
          },
          {
            title: translate('template.subject.label'),
            dataIndex: ['metadata', 'subject'],
          },
          {
            title: translate('template.from.label'),
            dataIndex: ['metadata', 'from'],
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'code',
                inputProps: {
                  placeholder: 'Code',
                },
              },
            ]}
          ></Crud.Search>
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
