import { useState } from 'react';
import { CrudForm } from '../../../containers/crud/crudForm';
import { BlockEditor } from '../../../containers/blockEditor';
import { ALPHANUMERIC_HYPENS } from '@arivaa-react/components/regex';
import { Input } from 'antd';
import { useSecureApi } from '../../../../hooks/api';
import { translate } from '../../../../localization';
import { DecryptConfig } from '../decryptConfig';

const types = ['file', 'text', 'html', 'boolean'];

const getValueElement = (type, { key, encrypted, updatedAt }) => {
  if (type == 'boolean') {
    return {
      required: false,
      type: 'switch',
    };
  } else if (type === 'html') {
    return {
      type: 'custom',
      Component: BlockEditor,
    };
  } else if (encrypted) {
    return {
      type: 'custom',
      inputProps: {
        configKey: key,
        AfterDecryptComponent: Input,
      },
      Component: DecryptConfig,
    };
  } else {
    return {
      type,
      fileCode: type === 'file' ? 'configurationFile' : undefined,
      inputProps:
        type === 'file'
          ? {
              uploadProps: {
                accept: '*/*',
                multiple: false,
              },
            }
          : {},
    };
  }
};

/**
 * @description User Form
 * @type Container
 * @author Inderdeep
 */
const Main = (props) => {
  const { data } = props;
  const [type, setType] = useState(data ? data.type : types[0]);
  const { callApi: refreshConfig } = useSecureApi();
  return (
    <CrudForm
      {...props}
      skipTranslate={true}
      elements={[
        {
          type: 'text',
          name: 'key',
          label: translate('configuration.key.label'),
          required: true,
          options: {
            rules: [
              {
                pattern: ALPHANUMERIC_HYPENS,
                message:
                  'Key can only be alphanumeric and can contain only hyphens (-)',
              },
            ],
          },
          inputProps: {
            disabled: !!data,
            className: 'uppercase',
          },
        },
        {
          type: 'select',
          name: 'type',
          label: translate('configuration.type.label'),
          required: true,
          data: types,
          options: {
            initialValue: type,
          },
          inputProps: {
            onChange: (value) => setType(value),
          },
        },
        {
          name: 'value',
          label: translate('configuration.value.label'),
          required: true,
          ...getValueElement(type, data || {}),
        },
        {
          type: 'textarea',
          name: 'description',
          label: translate('configuration.description.label'),
          required: true,
        },
        {
          type: 'switch',
          name: 'encrypted',
          label: translate('configuration.encrypted.label'),
          required: false,
        },
      ]}
      data={
        data
          ? {
              ...data,
              // Handling when encrypted is null
              encrypted: !!data?.encrypted,
            }
          : undefined
      }
      preSubmit={(values) => {
        if (!data) {
          values.key = values?.key?.toUpperCase();
        } else {
          values.encrypted = data.encrypted;
        }
        return values;
      }}
      onSuccess={() =>
        refreshConfig('/refresh-config', {
          method: 'GET',
        })
      }
    />
  );
};

Main.displayName = 'Configuration-Form';

export default Main;
