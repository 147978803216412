import React, { useEffect, useRef } from 'react';
import { Checkbox, message } from 'antd';
import Form from '@arivaa-react/components/form';
import { ALPHANUMERIC_HYPENS } from '@arivaa-react/components/regex';
import useWatchForm from '@arivaa-react/components/form/useWatchForm';
import createSmartInput from '@arivaa-react/components/createSmartInput';
import { useSpinner } from '../../../../hooks/useSpinner';
import { useApiWithSideEffects, useSecureApi } from '../../../../hooks/api';
import { url } from '../../../../constants/api';
import { useCrudData } from '../../../containers/crud/crudContext';
import { useModal } from '@arivaa-react/components/modal';
import { translate } from '../../../../localization';
const permissionTypes = ['Create', 'Update', 'Remove', 'Read'];
const spinnerSelector = '.ant-form';
/**
 * @description PDF Template Form
 * @type Container
 * @author Inderdeep
 */
const Main = (props) => {
  const { data } = props;
  const { refresh } = useCrudData();
  const { hideModal } = useModal();
  const form = useRef(null);

  const { data: permissions, callApi: getPermissions } = useApiWithSideEffects({
    spinnerSelector,
  });
  const { callApi } = useSecureApi();
  const type = useWatchForm(form, 'type');
  const handleSubmit = async (values) => {
    const spinner = useSpinner(spinnerSelector);
    spinner.start();
    try {
      // in case of edit
      if (data) {
        const { description, requiredPermissions } = values;
        await callApi(url`permission/${data.key}`, {
          method: 'PATCH',
          body: {
            description,
            requiredPermissions,
          },
        });
      } else {
        const {
          key,
          type,
          permissions,
          entity,
          description,
          requiredPermissions,
        } = values;
        if (type === 'CRUD') {
          await Promise.all(
            permissions.map(async (op) => {
              await callApi(url`permission`, {
                method: 'POST',
                body: {
                  key: (entity.toUpperCase() + '_' + op).toUpperCase(),
                  description: op + ' for ' + entity,
                  requiredPermissions,
                  type,
                },
              });
            })
          );
        }
        if (type === 'Custom') {
          await callApi(url`permission`, {
            method: 'POST',
            body: {
              key: key.toUpperCase(),
              description,
              requiredPermissions,
              type,
            },
          });
        }
      }
      message.success(translate('common.changes.save.success'));
      hideModal?.();
      refresh();
    } catch (e) {
      console.error(e);
      if (e?.response?.status === 409) {
        message.error(translate('permission.already.exist'));
      } else {
        message.error(translate('common.changes.save.error'));
      }
    }
    spinner.stop();
  };

  useEffect(() => {
    getPermissions(url`permission`, {
      params: {
        all: true,
      },
    });
  }, []);
  return (
    <Form
      ref={form}
      skipTranslate={true}
      elements={[
        ...(!data
          ? [
              {
                type: 'select',
                name: 'type',
                label: 'Type',
                required: true,
                data: ['CRUD', 'Custom'],
                options: {
                  initialValue: data ? 'Custom' : 'CRUD',
                },
              },
            ]
          : []),
        ...(type == 'CRUD'
          ? [
              {
                type: 'custom',
                required: true,
                name: 'permissions',
                label: 'CRUD Permissions',
                requiredMessage: 'Please select the permissions',
                Component: createSmartInput(
                  React.forwardRef(({ value, onChange }, ref) => {
                    return (
                      <>
                        <div className="select-all-checkboxes">
                          <Checkbox
                            onChange={(e) => {
                              form.current.setFieldsValue({
                                permissions: e.target.checked
                                  ? permissionTypes
                                  : [],
                              });
                            }}
                          >
                            Select All
                          </Checkbox>
                        </div>
                        <Checkbox.Group
                          ref={ref}
                          value={value}
                          onChange={onChange}
                        >
                          {permissionTypes.map((name, index) => {
                            return (
                              <Checkbox key={index} value={name}>
                                {name}
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </>
                    );
                  })
                ),
              },
              {
                type: 'text',
                name: 'entity',
                label: translate('permission.entity.label'),
                required: true,
              },
            ]
          : [
              {
                type: 'text',
                name: 'key',
                label: translate('permission.key.label'),
                required: true,
                options: {
                  rules: [
                    {
                      pattern: ALPHANUMERIC_HYPENS,
                      message:
                        'Key can only be alphanumeric and can contain only hyphens (-)',
                    },
                  ],
                  initialValue: data?.key,
                },
                inputProps: {
                  disabled: !!data,
                  className: 'uppercase',
                },
              },
              {
                type: 'textarea',
                name: 'description',
                label: translate('permission.description.label'),
                required: true,
                options: {
                  initialValue: data?.description,
                },
              },
            ]),
        {
          type: 'select',
          name: 'requiredPermissions',
          label: 'Required Permissions',
          required: false,
          inputProps: {
            mode: 'multiple',
            className: 'multi-select',
          },
          data: (permissions || [])
            .filter(({ key }) => {
              return key !== data?.key;
            })
            .map(({ key, description }) => {
              return {
                label: description,
                value: key,
              };
            }),
          options: {
            initialValue: data?.requiredPermissions,
          },
        },
      ]}
      onSubmit={handleSubmit}
    />
  );
};

Main.displayName = 'Permission-Form';

export default Main;
