import { Switch } from 'antd';
import { UpdateEntity } from '../../containers/updateEntity';
import { formatDate } from '@arivaa-react/helpers/date-util';
import { Crud } from '../../containers/crud/crud';
import { Table } from '../../containers/crud/table';
import { translate } from '../../../localization';
import { useCrudData } from '../../containers/crud/crudContext';
import { ProductForm } from './form';
import { WebCrud } from '../../containers/crud/webCrud';
export default function Product() {
  const { refresh } = useCrudData();
  return (
    <WebCrud api="product" FormComponent={ProductForm}>
      <Crud.Header title="Products">
        <Crud.Header.Actions.Common>
          <Crud.Header.Actions.Add
            modalProps={{
              className: 'large',
            }}
          />
        </Crud.Header.Actions.Common>
      </Crud.Header>
      <Crud.Search
        fields={[
          {
            name: 'title',
            type: 'text',
            inputProps: {
              placeholder: translate('common.title'),
            },
          },
          {
            name: 'shortDescription',
            type: 'text',
            inputProps: {
              placeholder: translate('product.shortDescription'),
            },
          },
          {
            label: translate('product.published'),
            name: 'published',
            type: 'custom',
            Component: Switch,
          },
          {
            label: translate('product.featured'),
            name: 'featured',
            type: 'custom',
            Component: Switch,
          },
        ]}
      ></Crud.Search>
      <Crud.Table
        actions={[
          ...Table.RowActions.Common,
          Crud.Table.RowActions.DuplicateRecordWithForm,
        ]}
        columns={[
          {
            title: translate('common.title'),
            dataIndex: 'title',
          },
          {
            title: translate('product.productUrl'),
            dataIndex: 'productUrl',
          },
          {
            title: translate('product.featured'),
            dataIndex: 'featured',
            render: (_, record) => {
              return (
                <UpdateEntity
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.featured}
                        onChange={async (checked) => {
                          await updateEntity({ featured: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: translate('product.published'),
            dataIndex: 'published',
            render: (_, record) => {
              return (
                <UpdateEntity
                  getChildren={({ loading, updateEntity }) => {
                    return (
                      <Switch
                        loading={loading}
                        checked={record.published}
                        onChange={async (checked) => {
                          await updateEntity({ published: checked });
                          refresh();
                        }}
                      />
                    );
                  }}
                />
              );
            },
          },
          {
            title: translate('product.createdAt'),
            dataIndex: 'createdAt',
            render: (text) => {
              return formatDate(text);
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
