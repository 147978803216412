import { useRef } from 'react';
import './styles.scss';
import { Layout, Menu } from 'antd';
import { MenuKeys } from './config';
import {
  getCurrentRoute,
  getRoute,
  goToRoute,
} from '@arivaa-react/react-router5';

import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useDrawer } from '../../../hooks/useDrawer';
import { translate } from '../../../localization';
import { useFeatureAccess } from '../../../hooks/useFeatureAccess';
const { SubMenu, Item } = Menu;
const { Sider: AntSider } = Layout;

export const Sider = () => {
  const { setDrawer } = useDrawer();
  const { hasAccess } = useFeatureAccess();
  const { permissions, role } = useCurrentUser().user;
  const { drawer } = useDrawer();
  const menuRef = useRef(null);
  const { routeKey } = getCurrentRoute();

  const menus = MenuKeys.filter((item) => {
    const routeProps = (getRoute(item.key) || {}).routeProps || {};
    return (
      role &&
      hasAccess(routeProps?.roles, routeProps?.permissions) &&
      (item.isAllowed instanceof Function
        ? !!item.isAllowed(permissions, role)
        : true)
    );
  });

  return role ? (
    <AntSider
      collapsible={true}
      collapsed={!drawer}
      className="sider"
      collapsedWidth={50}
      breakpoint="md"
      width={270}
    >
      <Menu
        theme="light"
        mode="inline"
        ref={menuRef}
        onClick={(e) => {
          goToRoute(e.key);
          mobileAndTabletcheck() && setDrawer(false);
        }}
        selectedKeys={menus
          .filter((item) => {
            return item.key === routeKey;
          })
          .map((item) => {
            return item.key;
          })}
      >
        {menus.map((item, index) => {
          const { icon: Icon, name, children, key, noTranslate } = item;

          if (children) {
            return (
              <SubMenu
                key={index}
                title={
                  <span>
                    <Icon />
                    <span>{noTranslate ? name : translate(name)}</span>
                  </span>
                }
              >
                {children.map((child) => {
                  const { name, key, noTranslate } = child;
                  return (
                    <Item
                      key={key}
                      className={
                        routeKey === key ? 'ant-menu-item-selected' : {}
                      }
                    >
                      <Icon />
                      <span>{noTranslate ? name : translate(name)}</span>
                    </Item>
                  );
                })}
              </SubMenu>
            );
          } else {
            return (
              <Item
                key={key}
                //className={(routeKey === key) ? 'ant-menu-item-selected' : ""}
              >
                <Icon />
                <span>{noTranslate ? name : translate(name)}</span>
              </Item>
            );
          }
        })}
      </Menu>
    </AntSider>
  ) : null;
};
