import LongText from '@arivaa-react/components/longtext';
import { WebCrud } from '../../containers/crud/webCrud';
import { Crud } from '../../containers/crud/crud';
import { translate } from '../../../localization';

export default function Contactranslate() {
  return (
    <WebCrud api="contact">
      <Crud.Header title="Contact">
        <Crud.Header.Actions>
          <Crud.Header.Actions.Refresh />
          <Crud.Header.Actions.Secondary>
            <Crud.Header.Actions.CsvImport />
            <Crud.Header.Actions.SampleCsvExport />
          </Crud.Header.Actions.Secondary>
        </Crud.Header.Actions>
      </Crud.Header>
      <Crud.Table
        columns={[
          {
            title: translate('support.email.label'),
            dataIndex: 'email',
          },
          {
            title: translate('support.name.label'),
            dataIndex: 'name',
          },
          {
            title: translate('support.message.label'),
            dataIndex: 'message',
            render: (message) => {
              return <LongText text={message} limit={25} title="Message" />;
            },
          },
          {
            title: translate('support.page.label'),
            dataIndex: 'page',
          },
          {
            title: 'Phone',
            dataIndex: 'otherInfo',
            render: (val) => {
              return val && val.phone ? val.phone : '';
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'name',
                inputProps: {
                  placeholder: 'Name',
                },
              },
              {
                type: 'text',
                name: 'email',
                inputProps: {
                  placeholder: 'Email',
                },
              },
              {
                type: 'text',
                name: 'message',
                inputProps: {
                  placeholder: 'Message',
                },
              },
              {
                type: 'input',
                name: 'otherInfo.phone',
                inputProps: {
                  placeholder: 'Phone Number',
                },
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
