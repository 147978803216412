import { WebCrud } from '../../containers/crud/webCrud';
import { Crud } from '../../containers/crud/crud';
import PermissionForm from './form';
import { translate } from '../../../localization';

export default function Permission() {
  return (
    <WebCrud api="permission" FormComponent={PermissionForm}>
      <Crud.Header title="Permissions">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Crud.Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new Code',
                  name: 'key',
                  inputProps: {
                    placeholder: 'Key',
                  },
                },
              ]}
            />
          ),
        ]}
        columns={[
          {
            title: translate('permission.key.label'),
            dataIndex: 'key',
          },
          {
            title: translate('permission.description.label'),
            dataIndex: 'description',
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'key',
                inputProps: {
                  placeholder: 'Key',
                },
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
