import { AuthFormWrapper } from '../../authFormWrapper/index';
import authenticationOptions from './options';
import { SocialSignin } from '../socialSignin';

/**
 * @description Signup Form
 * @type Component
 * @author Inderdeep
 */
const Main = ({ type, login }) => {
  const { SignupForm, Options, title } = authenticationOptions[type] || {};
  const optionKeys = Object.keys(authenticationOptions);
  return (
    <AuthFormWrapper title={title || 'Sign Up'}>
      {SignupForm && <SignupForm />}
      {optionKeys.length > 1 && (
        <div className="separator">
          <span className="line" />
          <span>OR CONTINUE WITH</span>
          <span className="line" />
        </div>
      )}
      <div className="links">
        {optionKeys.map((key) => {
          const { ButtonLink } = authenticationOptions[key];
          if (type === key) {
            return null;
          } else {
            return ButtonLink && <ButtonLink key={key} login={login} />;
          }
        })}
        <SocialSignin />
      </div>
      {Options && <Options />}
    </AuthFormWrapper>
  );
};
Main.displayName = 'Signup-Form';

export default Main;
