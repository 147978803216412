import { WebCrud } from '../../containers/crud/webCrud';
import { Crud } from '../../containers/crud/crud';
import { Table } from '../../containers/crud/table';
import { useCrudRecord } from '../../containers/crud/recordContext';
import { ModalTrigger } from '@arivaa-react/components/modal';
import { Button, Tooltip } from 'antd';
import { translate } from '../../../localization';
import { EyeOutlined } from '@ant-design/icons';
import { formatDate } from '@arivaa-react/helpers/date-util';
const AuditSettings = () => {
  const record = useCrudRecord();
  return (
    <ModalTrigger
      content={<pre>{JSON.stringify(record.payload, undefined, 4)}</pre>}
      modalProps={{
        title: translate('auditLog.changes'),
        footer: null,
      }}
    >
      <Tooltip title="View">
        <Button className="btn blue-btn-text">
          <EyeOutlined />
        </Button>
      </Tooltip>
    </ModalTrigger>
  );
};
export default function Audit() {
  return (
    <WebCrud api="audit-log">
      <Crud.Header title="Audit Log">
        <Crud.Header.Actions>
          <Crud.Header.Actions.Refresh />
        </Crud.Header.Actions>
      </Crud.Header>

      <Crud.Table
        actions={[Table.RowActions.Edit, () => <AuditSettings />]} //<AuditSettings/>  need to be added
        columns={[
          {
            title: translate('auditLog.createdBy'),
            dataIndex: 'createdByUser',
          },
          {
            title: translate('auditLog.role'),
            dataIndex: 'role',
          },
          {
            title: translate('auditLog.service'),
            dataIndex: 'service',
          },
          {
            title: translate('auditLog.operation'),
            dataIndex: 'operation',
          },
          {
            title: translate('auditLog.createdAt'),
            dataIndex: 'createdAt',
            render: (createdAt) => {
              return formatDate(createdAt);
            },
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                name: 'createdBy',
                type: 'text',
                inputProps: {
                  placeholder: translate('auditLog.createdBy'),
                },
              },
              {
                name: 'service',
                type: 'text',
                inputProps: {
                  placeholder: translate('auditLog.service'),
                },
              },
              {
                name: 'operation',
                type: 'text',
                inputProps: {
                  placeholder: translate('auditLog.operation'),
                },
              },
            ]}
          />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
