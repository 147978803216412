import { useCallback, useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import Form from '@arivaa-react/components/form';
import { useCrudData } from './crudContext';
import { useUrlHistoryForSearch } from '../../../hooks/useUrlHistoryForSearch';

/**
 * @description Advance Search Component
 * @type Component
 * @author
 */
export function Search({ fields = [], enableUrlHistory = true, formProps }) {
  const { updateQuery } = useCrudData();
  const [advance, setAdvance] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const defaultElements = fields.filter((ele) => !ele.advance);
  const formRef = useRef(null);
  const { clearSearchFromUrl, saveSearchInUrl, getSearchFromUrl } =
    useUrlHistoryForSearch();
  const clearSearch = useCallback(() => {
    setIsSubmitted(false);
    formRef.current.resetFields();
    updateQuery({
      search: [],
    });
    enableUrlHistory && clearSearchFromUrl();
  }, []);
  const submitSearch = useCallback((value) => {
    setIsSubmitted(true);
    updateQuery({
      search: Object.keys(value).reduce((arr, cur) => {
        const obj = fields?.find((o) => o.name === cur);
        return arr.concat(
          `${cur};${value[cur] || ''};${obj.operator || 'like'}`
        );
      }, []),
    });
  }, []);
  const onSubmit = useCallback((value) => {
    submitSearch(value);
    enableUrlHistory && saveSearchInUrl(value);
  }, []);

  useEffect(() => {
    if (enableUrlHistory) {
      const search = getSearchFromUrl();
      if (Object.keys(search).length > 0) {
        submitSearch(search);
        formRef.current.setFieldsValue(search);
      }
    }
  }, []);

  return (
    <div className="search form-search">
      <Form
        ref={formRef}
        onSubmit={onSubmit}
        submitButtonProps={{ children: 'Search' }}
        {...formProps}
        elements={[
          {
            type: 'inline',
            elements: advance ? fields : defaultElements,
          },
        ]}
        actions={[
          defaultElements.length === fields.length ? null : (
            <Button
              className="btn"
              key="advance"
              onClick={() => {
                setAdvance(!advance);
              }}
            >
              {(!advance ? 'Advanced' : 'Basic') + ' Search'}
            </Button>
          ),
          isSubmitted && (
            <Button
              key="clear"
              className="btn red-btn-text clear-btn"
              onClick={clearSearch}
              htmlType="button"
            >
              Clear Search
            </Button>
          ),
        ]}
      />
    </div>
  );
}
