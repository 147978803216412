import { BlockEditor } from '../../containers/blockEditor';
import { ALPHANUMERIC_HYPENS } from '@arivaa-react/components/regex';
import Link from '@arivaa-react/components/link';
import { WebCrud } from '../../containers/crud/webCrud';
import { Crud } from '../../containers/crud/crud';
import { CrudForm } from '../../containers/crud/crudForm';
import { Table } from '../../containers/crud/table';
import { translate } from '../../../localization';
import { useCrudRecord } from '../../containers/crud/recordContext';
import { Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

export default function Page() {
  return (
    <WebCrud
      api="page"
      FormComponent={(props) => (
        <CrudForm
          {...props}
          elements={[
            {
              type: 'text',
              name: 'title',
              label: translate('staticPage.title.label'),
              required: true,
            },
            {
              type: 'text',
              name: 'route',
              label: translate('staticPage.route.label'),
              options: {
                rules: [
                  {
                    pattern: ALPHANUMERIC_HYPENS,
                    message:
                      'Route can only be alphanumeric and can contain only hyphens (-)',
                  },
                ],
              },
              required: true,
            },
            {
              type: 'custom',
              name: 'html',
              label: translate('staticPage.html.label'),
              Component: BlockEditor,
              required: true,
            },
          ]}
        ></CrudForm>
      )}
    >
      <Crud.Header title="Static Pages">
        <Crud.Header.Actions.Common />
      </Crud.Header>
      <Crud.Table
        actions={[
          ...Table.RowActions.Common,
          () => (
            <Crud.Table.RowActions.DuplicateRecordWithForm
              elements={[
                {
                  type: 'text',
                  label: 'Enter new route',
                  name: 'route',
                  inputProps: {
                    placeholder: 'Route',
                  },
                },
              ]}
            />
          ),
          () => {
            const record = useCrudRecord();
            const { route } = record || {};
            return (
              <Tooltip title="Visit">
                <Link
                  className="ant-btn btn orange-btn-text visit"
                  target="_blank"
                  routeKey={'staticPages.' + route}
                >
                  <EyeOutlined />
                </Link>
              </Tooltip>
            );
          },
        ]}
        columns={[
          {
            title: translate('staticPage.title.label'),
            dataIndex: 'title',
          },
          {
            title: translate('staticPage.route.label'),
            dataIndex: 'route',
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
