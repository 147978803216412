/**
 * This is the client side entry point for the React app.
 */

import { Routes } from './routes';
//For providing polyfill for safari and older browsers for toBlob in canvas
import 'blueimp-canvas-to-blob';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import './styles';
import { configureLocalization } from './localization';
import { DataProvider } from './hooks/useData';
import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';
import { getAppConfig } from './hooks/useAppConfig';
import { App as AntdApp } from 'antd';
configureLocalization();

const App = () => {
  const { primaryColor = '', ...otherColors } = getAppConfig('theme') || {};
  //TODO : rename primaryColor to colorPrimary? as antd uses it
  const colors = {
    colorPrimary: primaryColor,
    primaryColor,
    ...otherColors,
  };
  const initialData = {
    drawer: !mobileAndTabletcheck(),
    horizontalMenu: false,
    theme: {
      colors,
      themeString: getAppConfig('themeString'),
    },
  };
  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        token: colors,
        components: {
          Button: {
            defaultHoverBorderColor: primaryColor,
            defaultHoverColor: primaryColor,
          },
        },
      }}
    >
      <DataProvider value={initialData}>
        <AntdApp>
          <Routes />
        </AntdApp>
      </DataProvider>
    </ConfigProvider>
  );
};

export default App;
