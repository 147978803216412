import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSpinner } from './useSpinner';
import { translate } from '../localization';
import { getAuthData } from './useAuth';
// Function to check if headers have content type json
const hasJsonHeader = (headers = {}) => {
  const headerName = 'Content-Type';
  return new Headers(headers).get(headerName)?.includes('application/json');
};

const createUrlParams = (params) => {
  if (!params || Object.keys(params).length === 0) {
    return '';
  }
  const urlSearchParams = new URLSearchParams();
  for (let key in params) {
    if (params[key] !== undefined) {
      if (params[key] instanceof Array) {
        params[key].forEach((item, index) => {
          urlSearchParams.append(`${key}[${index}]`, item);
        });
      } else if (typeof params[key] === 'object') {
        urlSearchParams.append(key, JSON.stringify(params[key]));
      } else {
        urlSearchParams.append(key, params[key]);
      }
    }
  }
  return `?${decodeURIComponent(urlSearchParams.toString())}`;
};

// API interceptors

const responseInterceptors = [];
const requestInterceptors = [];

function addInterceptor(interceptor, interceptorArr = []) {
  if (interceptor instanceof Function) {
    const index = interceptorArr.push(interceptor);
    return () => interceptorArr.splice(index, 1);
  }
}

export function addRequestInterceptor(interceptor) {
  return addInterceptor(interceptor, requestInterceptors);
}

export function addResponseInterceptor(interceptor) {
  return addInterceptor(interceptor, responseInterceptors);
}

export async function api(
  url,
  { body, params, method = 'GET', headers = {}, ...rest } = {}
) {
  headers = {
    ...(!(body instanceof FormData)
      ? {
          'Content-Type': 'application/json',
        }
      : {}),
    ...headers,
  };
  body = hasJsonHeader(headers) ? JSON.stringify(body) : body;
  const requestConfig = {
    headers,
    method,
    body,
    ...rest,
  };
  for (let interceptor of requestInterceptors) {
    await interceptor(requestConfig);
  }
  const response = await fetch(
    `${url}${createUrlParams(params)}`,
    requestConfig
  );
  const result = hasJsonHeader(response.headers) ? response.json() : response;
  for (let interceptor of responseInterceptors) {
    await interceptor(response, result);
  }
  if (!response?.ok) {
    throw await result;
  }
  return result;
}

export const secureApi = async (...args) => {
  // TODO: Add token below in a proper way
  const headers = {
    ...(args?.[1]?.headers || {}),
    authorization: getAuthData()?.accessToken,
  };
  args[1] = { ...args?.[1], headers };
  return await api(...args);
};

export const applyPromiseToasterEffects = async (
  promise,
  {
    successMessage,
    errorMessage = translate('common.request.error') ||
      'Error while processing the request',
    throwError = false,
  }
) => {
  try {
    const result = await promise;
    successMessage && message.success(successMessage);
    return result;
  } catch (e) {
    console.error(errorMessage || 'Error while processing the promise', e);
    message.error(errorMessage);
    if (throwError) {
      throw e;
    }
  }
};

export function useApi(apiHelper = api) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  let [requestCount, setRequestCount] = useState(0);
  const callApi = useCallback(
    async (...args) => {
      let resp;
      try {
        setLoading(true);
        setData();
        setRequestCount(requestCount++);
        resp = await apiHelper(...args);
        setData(resp);
      } catch (e) {
        console.error(
          `Error while making api request ${JSON.stringify(args)}`,
          { e }
        );
        setError(e);
        throw e;
      } finally {
        setLoading(false);
      }
      return resp;
    },
    [requestCount]
  );
  return {
    callApi,
    data,
    error,
    loading,
    requestCount,
  };
}

export function useSecureApi() {
  return useApi(secureApi);
}

export function useApiWithSideEffects({
  errorMessage,
  successMessage,
  spinnerSelector,
  secure = true,
}) {
  const { callApi, ...rest } = secure ? useSecureApi() : useApi();
  const spinnerHandler = useSpinner(spinnerSelector);
  const makeApiCall = useCallback(async (...args) => {
    //TODO : How to override the message here?
    spinnerSelector && spinnerHandler.start();
    let response;
    try {
      response = await callApi(...args);
      successMessage && message.success(successMessage);
    } catch (e) {
      console.error(`Error while making api request`, {
        e,
        args,
      });
      errorMessage && message.error(errorMessage);
    } finally {
      spinnerSelector && spinnerHandler.stop();
    }
    return response;
  });
  return {
    ...rest,
    callApi: makeApiCall,
  };
}

export function useAutoCallApi(apiHook = useApi, ...initialApiArgs) {
  const { callApi: initialCallApi, ...rest } = apiHook();
  const callApi = useCallback(
    (...fnApiArgs) => {
      return initialCallApi(
        ...(fnApiArgs.length > 0 ? fnApiArgs : initialApiArgs)
      );
    },
    [initialApiArgs]
  );
  useEffect(() => {
    callApi();
  }, []);
  return {
    ...rest,
    callApi,
  };
}
