import { useCallback } from 'react';
import { Button, Tooltip, message } from 'antd';
import './styles.scss';
import { PrinterOutlined } from '@ant-design/icons';
import { useSpinner } from '../../../hooks/useSpinner';
import { useSecureApi } from '../../../hooks/api';
import { url } from '../../../constants/api';
const spinningSelector = 'body';

export const Print = (props) => {
  const { callApi: getMarkUp } = useSecureApi();

  const print = useCallback(async () => {
    const spinner = useSpinner(spinningSelector);
    spinner.start();
    try {
      const res = await getMarkUp(url(`html-template/getMarkup`), {
        method: 'POST',
        body: {
          id: props.template,
          data: props.data,
        },
      });
      const { name, html } = res;
      const mywindow = window.open('', '_blank', 'height=400,width=600');
      mywindow.document.write(html);
      mywindow.document.title = name;
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.onload = mywindow.print;
      // setTimeout(() => {
      //     mywindow.print();
      // })
    } catch (e) {
      console.log(e);
      message.error('Error while fetching PDF Template');
    }
    spinner.stop();
    return true;
  }, [props]);

  return (
    <Tooltip placement="top" title={'Print PDF'}>
      <Button onClick={print} className="btn blue-btn-text">
        <PrinterOutlined />
      </Button>
    </Tooltip>
  );
};
