import { BlockEditor } from '../../../containers/blockEditor';
import TagInput from '@arivaa-react/components/tagInput';
import { CrudForm } from '../../../containers/crud/crudForm';

export const ProductForm = (props) => (
  <CrudForm
    {...props}
    elements={[
      {
        type: 'file',
        name: 'image',
        label: 'Featured Image',
        required: true,
        fileCode: 'mediaFile',
      },
      {
        type: 'file',
        name: 'productIcon',
        label: 'Product Icon',
        required: false,
        fileCode: 'mediaFile',
      },
      {
        type: 'text',
        name: 'title',
        label: 'Title',
        required: true,
        inputProps: {
          maxLength: 80,
        },
      },
      {
        type: 'text',
        name: 'productUrl',
        label: 'Url path or External Url',
        required: true,
        inputProps: {
          maxLength: 200,
        },
      },
      {
        type: 'text',
        name: 'shortDescription',
        label: 'Short Description',
        required: true,
        inputProps: {
          maxLength: 240,
        },
      },
      {
        type: 'text',
        name: 'purchaseUrl',
        label: 'Purchase Url',
        required: false,
        inputProps: {
          maxLength: 500,
        },
      },
      {
        type: 'text',
        name: 'price',
        label: 'Price',
        required: true,
        inputProps: {
          maxLength: 500,
        },
      },
      {
        type: 'custom',
        Component: BlockEditor,
        name: 'description',
        label: 'Content',
        required: true,
      },
      {
        type: 'custom',
        Component: TagInput,
        name: 'tags',
        label: 'Tags',
        required: false,
      },
      {
        type: 'switch',
        name: 'published',
        label: 'Published',
        required: false,
      },
      {
        type: 'switch',
        name: 'featured',
        label: 'Featured',
        required: false,
      },
    ]}
  />
);
