import { BlockEditor } from '../../../containers/blockEditor';
import TagInput from '@arivaa-react/components/tagInput';
import { Select } from 'antd';
import { CrudForm } from '../../../containers/crud/crudForm';
import { useAutoCallApi, useSecureApi } from '../../../../hooks/api';
import { Row, Col } from 'antd';
import { url } from '../../../../constants/api';
import { translate } from '../../../../localization';
const imageElement = {
  type: 'file',
  name: 'image',
  label: 'Image',
  required: true,
  fileCode: 'mediaFile',
};
export const BlogPostForm = (props) => (
  <CrudForm
    {...props}
    fileElements={[imageElement]}
    renderForm={({ renderElement }) => {
      return (
        <div className="template-builder">
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="form">
                {renderElement({
                  type: 'custom',
                  name: 'category',
                  label: translate('blogPost.category'),
                  Component: (props) => {
                    const { data } = useAutoCallApi(
                      useSecureApi,
                      url('blog-category'),
                      {
                        params: {
                          sort: 'createdAt',
                          sortType: 'desc',
                          all: true,
                        },
                      }
                    );
                    return (
                      <Select
                        placeholder="Select Category"
                        className="select"
                        {...props}
                      >
                        {(data || []).map(({ key, title }, index) => {
                          return (
                            <Select.Option key={index} value={key}>
                              {title}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    );
                  },
                  required: true,
                })}
                {renderElement(imageElement)}
                {renderElement({
                  type: 'text',
                  name: 'title',
                  label: 'Title',
                  required: true,
                  inputProps: {
                    maxLength: 80,
                  },
                })}
                {renderElement({
                  type: 'text',
                  name: 'shortDescription',
                  label: 'Short Description',
                  required: true,
                  inputProps: {
                    maxLength: 240,
                  },
                })}
                {renderElement({
                  type: 'custom',
                  Component: TagInput,
                  name: 'tags',
                  label: 'Tags',
                  required: false,
                })}
                {renderElement({
                  type: 'switch',
                  name: 'published',
                  label: 'Published',
                  required: false,
                })}
                {renderElement({
                  type: 'switch',
                  name: 'featured',
                  label: 'Featured',
                  required: false,
                })}
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="preview">
                {renderElement({
                  type: 'custom',
                  name: 'description',
                  label: translate('blogPost.description'),
                  required: true,
                  Component: BlockEditor,
                })}
              </div>
            </Col>
          </Row>
        </div>
      );
    }}
  />
);
