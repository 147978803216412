import { UpdateEntity } from '../updateEntity';
import { Select } from 'antd';
import { useCrudData } from '../crud/crudContext';

export const UpdatableDropDown = ({
  keyPropName,
  selectProps,
  onSuccess,
  onError,
  updateEntityProps,
  updatePropName,
  options = [],
  defaultValue,
}) => {
  const { apiUrl } = useCrudData();
  return (
    <UpdateEntity
      {...updateEntityProps}
      keyPropName={keyPropName || 'key'}
      getChildren={({ updateEntity }) => {
        return (
          <Select
            {...selectProps}
            value={defaultValue}
            onChange={async (value) => {
              try {
                await updateEntity({ [updatePropName]: value });
                onSuccess instanceof Function && onSuccess(value);
              } catch (error) {
                console.error(
                  `Error while updating entity ${
                    apiUrl?.split('/')[1]
                  } error: ${error}`
                );
                onError instanceof Function && onError(error);
              }
            }}
          >
            {options.map(({ label, value }, index) => {
              return (
                <Select.Option key={index} value={`${value}`}>
                  {label}
                </Select.Option>
              );
            })}
          </Select>
        );
      }}
    />
  );
};
