import { WebCrud } from '../../containers/crud/webCrud';
import { Crud } from '../../containers/crud/crud';
import { Table } from '../../containers/crud/table';

export default function UnsubscribedEmails() {
  return (
    <WebCrud api="unsubscribed-emails">
      <Crud.Header title="Unsubscribed Emails">
        <Crud.Header.Actions>
          <Crud.Header.Actions.Refresh />
          <Crud.Header.Actions.Secondary>
            <Crud.Header.Actions.CsvImport />
            <Crud.Header.Actions.SampleCsvExport />
          </Crud.Header.Actions.Secondary>
        </Crud.Header.Actions>
      </Crud.Header>
      <Crud.Table
        actions={[Table.RowActions.Delete]}
        columns={[
          {
            title: 'Email',
            dataIndex: 'email',
          },
          {
            title: 'Email Key',
            dataIndex: 'emailKey',
          },
        ]}
      >
        <Crud.Table.TableActions.Common />
        <Crud.Table.Header>
          <Crud.Search
            fields={[
              {
                type: 'text',
                name: 'email',
                inputProps: {
                  placeholder: 'Email',
                },
              },
            ]}
          ></Crud.Search>
          <Crud.Table.BulkRowActions.Common />
        </Crud.Table.Header>
      </Crud.Table>
    </WebCrud>
  );
}
