import './styles.scss';
import Graphic from '../../components/graphic/index';
import bg from '../../images/bg.jpg';
import { useTheme } from '../../hooks/useTheme';
/**
 * @description Auth Form Wrapper for all auth related forms like login, signup etc
 * @type Component
 * @author Inderdeep
 */
export const AuthFormWrapper = ({ children, title, description }) => {
  const { primaryColor } = useTheme().colors;
  return (
    <>
      <div className="form-container">
        <div className="auth-form">
          {title && <p className="title">{title}</p>}
          {description && <p className="description">{description}</p>}
          {children}
        </div>
      </div>
      <Graphic reverse gradient image={bg} primaryColor={primaryColor} />
    </>
  );
};
